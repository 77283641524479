import React, { useState, useRef } from "react";
import img1 from "./image/banner.jpg";
import { Link } from "react-router-dom";

const onlineIcons = [
  "https://img.icons8.com/color/48/000000/graduation-cap.png",
  "https://img.icons8.com/color/48/000000/open-book.png",
  "https://img.icons8.com/color/48/000000/idea.png",
  "https://img.icons8.com/color/48/000000/certificate.png",
];

const UniversityBanner = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    course: "",
  });

  // Create a ref for the enquiry form container
  const formRef = useRef(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Called when any "Apply Now" button is clicked.
  // It sets the course and scrolls the enquiry form into view.
  const handleApplyNow = (course) => {
    setFormData({ ...formData, course });
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.name || !formData.email || !formData.phone || !formData.course) {
      alert("Please fill in all fields");
      return;
    }

    const whatsappMessage = `Hello, I would like to inquire about Haridwar University:\n\nName: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCourse: ${formData.course}`;
    const whatsappURL = `https://wa.me/919875872324?text=${encodeURIComponent(whatsappMessage)}`;

    window.open(whatsappURL, "_blank");
  };

  return (
    <div className="w-full flex justify-center bg-gradient-to-br from-blue-50 to-blue-100 relative overflow-hidden">
      <div className="md:w-[98%] w-full relative flex flex-col lg:flex-row items-center justify-between px-6 py-2 lg:py-20 space-y-12 lg:space-y-0">
        <div className="w-full lg:w-1/2 text-left space-y-6 pt-[4rem]">
          <h1 className="text-2xl md:text-4xl lg:text-5xl font-bold text-gray-800 leading-tight">
            Discover Excellence at{" "}
            <span className="text-blue-700">Haridwar University</span>
          </h1>
          <p className="text-sm md:text-lg text-gray-600">
            Explore top programs tailored to shape your future with innovative learning experiences.
          </p>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 mt-6 hidden md:flex">
  {["BCA", "MCA", "MBA", "B.Tech"].map((course, index) => {
    const durations = {
      "BCA": "3 Years",
      "MCA": "2 Years",
      "MBA": "2 Years",
      "B.Tech": "4 Years"
    };
    return (
      <div
        key={index}
        className="p-4 bg-white flex flex-col items-center text-center space-y-4 transform transition duration-300"
      >
        <h4 className="text-sm md:text-xl font-semibold text-gray-800">
          {course}
        </h4>
        <p className="text-xs md:text-sm text-gray-600">
          Duration: {durations[course]}
        </p>
        <Link to="/enquiry">
          <button className="mt-4 bg-orange-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-orange-600 transition duration-300">
            Apply Now
          </button>
        </Link>
      </div>
    );
  })}
</div>
        </div>

        <div className="md:w-[40%] flex w-full">
          <div
            ref={formRef}
            className="lg:bottom-0 right-0 w-[100%] md:w-[60%] bg-white md:p-6 p-2 transform transition duration-300 hover:scale-105 space-y-4"
          >
            <h3 className="text-lg md:text-2xl font-semibold text-gray-800 text-center">
              Get in Touch
            </h3>
            <form onSubmit={handleSubmit} className="space-y-4">
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-700 text-[12px] md:text-base"
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-700 text-[12px] md:text-base"
                required
              />
              <input
                type="tel"
                name="phone"
                placeholder="Your Phone Number"
                value={formData.phone}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-700 text-[12px] md:text-base"
                required
              />
              <input
                type="text"
                name="course"
                placeholder="Course"
                value={formData.course}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-700 text-[12px] md:text-base"
                required
              />
              <button
                type="submit"
                className="w-full bg-blue-700 text-white px-4 py-2 rounded-lg font-semibold hover:bg-blue-800 transition duration-300 text-sm md:text-base"
              >
                Submit
              </button>
            </form>
            {/* <CallToAction/> */}
          </div>

          <div className="w-full md:w-[80%] max-h-screen flex items-center justify-center relative">
            <img
              src={img1}
              alt="Haridwar University"
              className="w-full h-full md:h-full object-cover"
              autoPlay
              muted
              loop
            />
          </div>
        </div>
      </div>

      <div className="absolute md:top-[5%] top-[5%] w-full flex justify-around px-4 md:px-12 hidden md:flex">
        {onlineIcons.map((icon, index) => (
          <img
            key={index}
            src={icon}
            alt={`Flat Icon ${index + 1}`}
            className="w-10 md:w-12 h-10 md:h-12"
          />
        ))}
      </div>
    </div>
  );
};

export default UniversityBanner;
