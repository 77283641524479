import React from "react";
import { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="w-full flex justify-center">
      <div className="bg-white-100 p-6 rounded-lg shadow-2xl w-[87%] my-12">
        <h1 className="text-2xl font-bold mb-8 mt-4 text-center">Privacy Policy</h1>
        <div className="blog-content">
          <h2 className="font-bold mt-4">Introduction</h2>
          <p>
            This privacy policy outlines how [Your Company Name] collects, uses, and protects
            personal information collected from users of our website https://aaopadhe.in. By using
            our website, you agree to the terms of this policy.
          </p>
          <h3 className="text-md font-bold mt-4">Information Collected</h3>
          <p>
          We may collect personal information when you visit our website, such as your name, email address, phone number, and other contact details. We may also collect non-personal information such as your IP address, browser type, and operating system.
Cookies and Tracking Technologies
Our website may use cookies and other tracking technologies to improve the user experience and provide personalized content. Cookies are small text files that are placed on your computer or device when you visit our website. They allow us to track your browsing behavior and preferences. You can disable cookies in your browser settings, but this may affect your ability to use some features of our website.
Use of Information
We use the information collected from our website to provide educational and administrative services to our students, faculty, and staff. We may also use the information for marketing purposes or to improve the user experience on our website.
Disclosure of Information
We may share personal information with third-party service providers to help us manage our website or provide other services. We may also disclose personal information in response to a legal request or to protect our rights or property.
Security
We take reasonable measures to protect personal information from unauthorized access, use, or disclosure. However, we cannot guarantee the security of information transmitted over the internet.
Your Rights
You have the right to access, correct, and delete personal information we have collected from you. To exercise these rights, please contact us at .
Changes to this Policy
We may update this privacy policy from time to time. The latest version of the policy will be posted on our website.
Conclusion
We take your privacy seriously and are committed to protecting your personal information. If you have any questions or concerns about this policy, please contact us
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
