import React from "react";

// Sample course data array
const courses = [
  {
    name: "B.tech(Hons.)",
    fee: "₹ 259,000/total",
    details: ["4 Year", "On-campus", "Full Time"],
  },
  {
    name: "B.Sc Computer Science",
    fee: "₹ 1,67,000/total",
    details: ["3 Year", "On-campus", "Full Time"],
  },
  {
    name: "MCA",
    fee: "₹ 1,64,000/total",
    details: ["2 Year", "On-campus", "Full Time"],
  },
  {
    name: "BCA",
    fee: "₹ 2,12,000/total",
    details: ["3 Year", "On-campus", "Full Time"],
  },
  {
    name: "MSC",
    fee: "₹ 1,19,000/total",
    details: ["2 Year", "On-campus", "Full Time"],
  },
  {
    name: "BBA",
    fee: "₹ 2,12,000/total",
    details: ["3 Year", "On-campus", "Full Time"],
  },
  {
    name: "MBA",
    fee: "₹ 4,50,000/total",
    details: ["2 Year", "On-campus", "Full Time"],
  },
  {
    name: "B.com (Hons)",
    fee: "₹ 1,67,000/total",
    details: ["3 Year", "On-campus", "Full Time"],
  },
  {
    name: "Bachelors in Hotel Management",
    fee: "₹ 1,08,000/total",
    details: ["4 Year", "On-campus", "Full Time"],
  },
  {
    name: "Bachelors in Agriculture",
    fee: "₹ 1,08,000/total",
    details: ["4 Year", "On-campus", "Full Time"],
  },
];

const FeesTable = () => {
  return (
    <div className="overflow-x-auto p-4">
      <table className="min-w-full border-collapse">
        <thead>
          <tr className="bg-gray-200">
            <th className="border px-4 py-2 text-left">Course Name</th>
            <th className="border px-4 py-2 text-left">Fee</th>
            <th className="border px-4 py-2 text-left">Duration</th>
          </tr>
        </thead>
        <tbody>
          {courses.map((course, index) => (
            <tr key={index} className="hover:bg-gray-100">
              <td className="border px-4 py-2">{course.name}</td>
              <td className="border px-4 py-2">{course.fee}</td>
              <td className="border px-4 py-2">{course.details[0]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FeesTable;
