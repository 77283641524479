import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonals = ({ uni }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full py-12 bg-white text-white flex justify-center items-center">
      <div className="w-[90%] max-w-6xl">
        <h2 className="text-3xl md:text-4xl font-extrabold text-center mb-8 bg-clip-text text-transparent bg-gradient-to-r from-yellow-300 to-orange-500">
          Our Placed Students
        </h2>
        <Slider {...settings}>
          {uni?.ourPlacedStudent?.map((item, ind) => (
            <div key={ind} className="px-4 py-8">
              <div className="bg-white/10 backdrop-blur-lg border border-white/30 p-6 rounded-2xl shadow-lg text-center flex flex-col items-center">
                <img
                  src={item.perImg}
                  alt="Student"
                  className="w-24 h-24 md:w-32 md:h-32 rounded-full border-4 border-yellow-400 shadow-lg mb-4"
                />
                <h3 className="text-xl font-bold text-yellow-300">{item.Name}</h3>
                <p className="text-sm text-gray-800">{item.work}</p>
                <img src={item.Topimg} alt="Company" className="w-20 h-10 mt-3" />
                <p className="text-sm text-gray-700 italic mt-4">"{item.Review}"</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonals;