import React from "react";
import { motion } from "framer-motion";
import InfiAnimation from "../../../extra/infiAnimation/index";
import { FaBuilding, FaBriefcase, FaHandshake } from "react-icons/fa";

const HiringPartner = ({ uni }) => {
  return (
    <div className="relative w-full py-4 flex flex-col items-center justify-center overflow-hidden text-gray-800 px-4 md:px-8 bg-gradient-to-b from-blue-50 to-indigo-50">
      {/* Background Gradient */}
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-blue-50 to-indigo-50"></div>

      {/* Overlay */}
      <div className="absolute inset-0 bg-white/30 backdrop-blur-md"></div>

      {/* Title */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="relative text-center px-4 md:px-12 z-10"
      >
        <h1 className="text-2xl md:text-4xl font-extrabold tracking-wide drop-shadow-xl bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-indigo-600">
           Hiring Partners of {uni ? uni.university : "Internal Server Error"}
        </h1>
      </motion.div>

      {/* Icons Section */}
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, delay: 0.3 }}
        className="relative flex flex-wrap gap-8 justify-center mt-6 z-10"
      >
        <div className="flex flex-col items-center justify-center w-20 h-20 md:w-24 md:h-24 bg-white rounded-2xl shadow-lg p-4 border border-blue-100 hover:scale-110 transition-transform duration-300">
          <FaBuilding className="text-blue-500 text-4xl md:text-5xl" />
        </div>
        <div className="flex flex-col items-center justify-center w-20 h-20 md:w-24 md:h-24 bg-white rounded-2xl shadow-lg p-4 border border-blue-100 hover:scale-110 transition-transform duration-300">
          <FaBriefcase className="text-blue-500 text-4xl md:text-5xl" />
        </div>
        <div className="flex flex-col items-center justify-center w-20 h-20 md:w-24 md:h-24 bg-white rounded-2xl shadow-lg p-4 border border-blue-100 hover:scale-110 transition-transform duration-300">
          <FaHandshake className="text-blue-500 text-4xl md:text-5xl" />
        </div>
      </motion.div>

      {/* Animation Section */}
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, delay: 0.5 }}
        className="relative w-full max-w-6xl mt-8 flex items-center justify-center z-10"
      >
        <div className="bg-white backdrop-blur-lg border border-blue-100 p-6 rounded-2xl shadow-lg w-full">
          <InfiAnimation uni={uni} />
        </div>
      </motion.div>
    </div>
  );
};

export default HiringPartner;