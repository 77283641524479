import React from "react";
import { BiMap } from "react-icons/bi";
import { BsArrow90DegRight } from "react-icons/bs";
import BottomImage from "./image/slider4.jpg";
import { motion } from "framer-motion";

const OfficeAddress = ({ uni }) => {
  return (
    // bg-gradient-to-br from-indigo-300 via-purple-700 to-pink-600
    <section className="w-full md:py-16 py-2 bg-gray-100 text-black">
      <div className="flex flex-wrap w-[90%] max-w-7xl mx-auto items-center justify-between gap-10">
        {/* Left Content */}
        <motion.div 
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
          className="w-full lg:w-[45%] flex flex-col items-start text-left"
        >
          <h1 className="text-xl md:text-5xl font-extrabold leading-tight">
            Need More Info? <br />
            <span className="text-yellow-300">Visit Us</span>
          </h1>
          <p className="md:mt-4 mt-1 text-gray-800 md:text-lg text-sm">
            Confused about which course to choose? Our expert admission counselors are here to guide you through the selection process, providing complete admission information and personalized recommendations.
          </p>
          import { motion } from "framer-motion";

<motion.button
  whileHover={{ scale: 1.05 }}
  transition={{ duration: 0.3 }}
  className="mt-6 px-6 py-3 bg-yellow-400 text-black font-semibold rounded-lg hover:bg-yellow-500 transition-all"
  onClick={() => window.location.href = "tel:+919875872324"}
>
  Call Now
</motion.button>


          {/* Address */}
          <div className="mt-6 flex items-center gap-3 text-sm md:text-lg">
            <BiMap className="text-yellow-400 md:text-2xl" />
            <p>
              Visit Us:{" "}
              <span className="text-yellow-300 cursor-pointer">
                (10 AM - 7 PM)
              </span>
            </p>
          </div>
          <div className="mt-2 flex items-center gap-3 md:text-lg text-sm">
            <BsArrow90DegRight className="text-yellow-400 text-2xl" />
            <p className="font-semibold">
              {uni?.university} <br />
              {uni?.address}
            </p>
          </div>
        </motion.div>

        {/* Right Image */}
        <motion.div 
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
          className="w-full lg:w-[50%] flex justify-center items-center"
        >
          <img
            src={BottomImage}
            alt="Office Location"
            className="w-full max-w-lg rounded-2xl shadow-lg hover:scale-105 transition-transform duration-500"
          />
        </motion.div>
      </div>
    </section>
  );
};

export default OfficeAddress;
