import React from "react";

const Scholarships = ({ uni }) => {
  const scholarshipData = [
    { label: "Scholarship Type", value: "College-specific" },
    { label: "Organisation", value: uni.university },
    { label: "Application Deadline", value: uni.applicationDeadline },
    { label: "No. of Scholarships", value: uni.noScholarships },
    { label: "Amount", value: uni.amount },
    { label: "International Students", value: uni.internationalStudents },
  ];

  return (
    <div className="w-full py-12 bg-gradient-to-b from-blue-50 to-indigo-50 text-gray-800 flex justify-center items-center px-4 md:px-8">
      <div className="w-[90%] max-w-4xl bg-white backdrop-blur-lg border border-blue-100 p-6 md:p-10 rounded-2xl shadow-xl text-center">
        <h2 className="text-3xl md:text-4xl font-extrabold text-center mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-indigo-600">
          Scholarships Available
        </h2>
        <div className="overflow-x-auto">
          <table className="w-full text-sm md:text-md text-left text-gray-800">
            <tbody>
              {scholarshipData.map((item, index) => (
                <tr
                  key={index}
                  className="border-b border-blue-100 hover:bg-blue-50 transition duration-300"
                >
                  <td className="px-4 py-3 font-semibold text-blue-800">{item.label}</td>
                  <td className="px-4 py-3 text-gray-700">{item.value}</td>
                </tr>
              ))}
              {/* <tr>
                <td className="px-4 py-4 font-semibold text-blue-800">Scholarship Link</td>
                <td className="px-4 py-4">
                  <a
                    href={uni.scholarshipLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-block px-4 py-2 md:px-6 md:py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition-transform duration-300 shadow-md text-sm md:text-base"
                  >
                    Apply Now
                  </a>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Scholarships;