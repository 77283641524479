import React, { useState } from "react";
import image from "./image/header-logo.jpg";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="w-full bg-white shadow-lg fixed top-0 left-0 z-50 py-3 transition-all duration-300">
      <div className="container mx-auto flex justify-between items-center px-6">
        {/* Logo */}
        <Link to="/" className="flex-shrink-0">
          <img className="h-12" src={image} alt="Logo" />
        </Link>

        {/* Hamburger Menu Button */}
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="md:hidden p-3 text-[#1e3a8a] border border-[#2563eb] rounded-full focus:outline-none transition duration-300 hover:bg-[#2563eb] hover:text-white"
        >
          {isOpen ? "✖" : "aaopadhe"}
        </button>

        {/* Navigation Links */}
        <div
          className={`md:flex md:items-center md:space-x-6 ${
            isOpen
              ? "absolute top-16 left-0 w-full bg-white shadow-lg py-4 px-6"
              : "hidden"
          }`}
        >
          <Link
            to="/"
            className="block text-[#1e3a8a] hover:text-[#2563eb] font-semibold transition duration-300 py-2"
          >
            Home
          </Link>
          <Link
            to="/about"
            className="block text-[#1e3a8a] hover:text-[#2563eb] font-semibold transition duration-300 py-2"
          >
            About
          </Link>
          <Link
            to="/contact"
            className="block text-[#1e3a8a] hover:text-[#2563eb] font-semibold transition duration-300 py-2"
          >
            Contact
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
