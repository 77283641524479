import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { MdGroups3 } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";
import { FaReact } from "react-icons/fa";
import feature1 from "./image/hari3.webp";
import feature2 from "./image/hari2.webp";
import feature3 from "./image/1 (5).webp";

const OurFeatures = ({ uni }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const images = [feature1, feature2, feature3];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % images.length);
    }, 3000);
    return () => clearInterval(intervalId);
  }, [images.length]);

  const features = [
    {
      icon: <MdGroups3 />,
      subHed: "Skilled Teachers",
      preGraph:
        "Our university is home to highly experienced and passionate educators dedicated to academic excellence and student success.",
    },
    {
      icon: <GiReceiveMoney />,
      subHed: "Affordable Courses",
      preGraph:
        "We offer a wide range of affordable programs designed to make quality education accessible to everyone.",
    },
    {
      icon: <FaReact />,
      subHed: "Efficient & Flexible",
      preGraph:
        "Our learning modules are designed for flexibility, allowing students to study at their own pace and convenience.",
    },
  ];

  return (
    <div className="relative w-full py-12 flex flex-col items-center justify-center overflow-hidden text-gray-800 px-4 md:px-8 bg-gradient-to-b from-blue-50 to-indigo-50">
      {/* Background Overlay */}
      <div className="absolute inset-0 bg-white/30 backdrop-blur-md"></div>

      {/* Title Section */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="relative text-center px-4 md:px-12 z-10"
      >
        <h1 className="text-xl md:text-4xl font-extrabold tracking-wide drop-shadow-xl bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-indigo-600">
          Best Features at {uni ? uni.university : "Our University"}
        </h1>
        <p className="text-md text-gray-600 mt-4 max-w-xl mx-auto">
          Experience world-class education with top-notch facilities and expert faculty.
        </p>
      </motion.div>

      {/* Features and Image Slider Section */}
      <div className="relative w-full max-w-6xl mt-6 grid grid-cols-1 lg:grid-cols-2 gap-6 z-10">
        {/* Features List */}
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, delay: 0.3 }}
          className="relative bg-white backdrop-blur-lg border border-blue-100 p-6 rounded-2xl shadow-lg"
        >
          {features.map((feature, index) => (
            <div key={index} className="flex items-start gap-4 mb-4">
              <div className="flex items-center justify-center w-16 h-16 md:w-20 md:h-20 bg-blue-50 rounded-full shadow-lg border border-blue-100 text-blue-500 text-3xl">
                {feature.icon}
              </div>
              <div>
                <h4 className="text-md font-bold text-blue-800">{feature.subHed}</h4>
                <p className="text-sm text-gray-600 mt-1">{feature.preGraph}</p>
              </div>
            </div>
          ))}
        </motion.div>

        {/* Image Slider */}
        <div className="relative w-full h-60 md:h-80 overflow-hidden rounded-2xl shadow-lg">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Feature ${index + 1}`}
              className={`absolute w-full h-full object-cover transition-opacity duration-1000 ${
                index === currentSlide ? "opacity-100" : "opacity-0"
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurFeatures;
