import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Courses = ({ uni }) => {
  const [coursesdata, setCoursesData] = useState([]);

  useEffect(() => {
    if (uni && uni.courses) {
      setCoursesData(uni.courses);
    }
  }, [uni]);

  return (
    <div className="relative w-full py-12 flex flex-col items-center justify-center text-gray-800 px-4 md:px-8 bg-white">
      {/* Background Gradient */}
      <div className="absolute top-0 left-0 w-full h-full bg-white"></div>
      {/* Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-white via-white to-white"></div>
      {/* Section Title */}
      <motion.div 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="relative text-center px-4 md:px-12 z-10"
      >
        <h1 className="text-xl md:text-5xl font-extrabold tracking-wide bg-clip-text text-transparent bg-gradient-to-r from-blue-300 to-blue-500">
          Explore {uni.university} Courses
        </h1>
        <p className="text-sm md:text-lg text-gray-600 mt-4 max-w-xl mx-auto">
          Unlock new learning opportunities with our diverse course offerings.
        </p>
      </motion.div>
      {/* Course Cards */}
      <motion.div 
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="relative w-full max-w-6xl mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 z-10"
      >
        {coursesdata.map((course, index) => (
          <motion.div 
            key={index} 
            whileHover={{ scale: 1.05 }}
            className="bg-white backdrop-blur-lg border border-gray-200 p-4 rounded-lg shadow-lg flex flex-col transition-all duration-300 hover:bg-gray-50"
          >
            <h2 className="text-lg font-bold text-gray-800">{course.name}</h2>
            {course.description && (
              <p className="text-sm text-gray-600 mt-1">{course.description}</p>
            )}
            {course.eligibility && (
              <div className="text-sm text-gray-600 mt-1">
                Eligibility: {course.eligibility}
              </div>
            )}
            <Link to="/enquiryform" className="mt-auto">
              <button className="mt-2 px-4 py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 transition-all duration-200 w-full">
                Apply Now
              </button>
            </Link>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default Courses;