import React, { useState } from "react";
import { motion } from "framer-motion";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const UniFaq = ({ uni }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <section className="w-full py-16 bg-gradient-to-b from-indigo-900 via-purple-800 to-indigo-900 text-white">
      <div className="max-w-5xl mx-auto px-6">
        {/* Section Heading */}
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-3xl md:text-4xl font-extrabold text-center mb-8 bg-clip-text text-transparent bg-gradient-to-r from-yellow-400 to-orange-500 drop-shadow-lg"
        >
          Frequently Asked Questions
        </motion.h2>

        {/* FAQ List */}
        <div className="space-y-4">
          {uni?.doubtSection?.map((item, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="bg-white/10 border border-white/30 backdrop-blur-md rounded-2xl overflow-hidden shadow-lg hover:shadow-2xl transition duration-300"
            >
              {/* Question Block */}
              <div
                className="p-5 flex justify-between items-center cursor-pointer text-lg font-semibold hover:bg-white/20 transition duration-300 rounded-t-2xl"
                onClick={() => toggleAccordion(index)}
              >
                <span>{item.question}</span>
                <motion.span
                  animate={{ rotate: activeIndex === index ? 180 : 0 }}
                  transition={{ duration: 0.3 }}
                  className="text-yellow-400 text-2xl"
                >
                  {activeIndex === index ? <FiChevronUp /> : <FiChevronDown />}
                </motion.span>
              </div>

              {/* Answer Block */}
              {activeIndex === index && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.4 }}
                  className="p-5 bg-white/15 border-t border-white/20 rounded-b-2xl text-gray-200"
                >
                  <ul className="list-disc px-6 space-y-2 text-gray-300">
                    <li>{item.answer1}</li>
                    {item.answer2 && <li>{item.answer2}</li>}
                  </ul>
                </motion.div>
              )}
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default UniFaq;
