import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import image1 from "./s-blogimg-01.png";
import image2 from "./s-blogimg-02.png";

function Footer({ uni }) {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="bg-gradient-to-b from-indigo-900 to-purple-900 text-white py-12">
      <div className="container mx-auto w-[90%] max-w-7xl">
        {/* Footer Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10">
          
          {/* About Us */}
          <div>
            <h4 className="text-xl font-semibold text-yellow-300 mb-4">About Us</h4>
            <p className="text-gray-300 leading-relaxed">
              {uni?.university} supports its students in achieving success at every level. 
              Our institution offers top-tier education and career opportunities across various disciplines.
            </p>
            {/* Social Icons */}
            <div className="flex gap-3 mt-4">
              {[FaFacebookF, FaInstagram, FaTwitter].map((Icon, index) => (
                <div key={index} className="p-2 bg-white/20 rounded-full hover:bg-yellow-300 hover:text-black transition-all cursor-pointer">
                  <Icon className="text-lg" />
                </div>
              ))}
            </div>
          </div>

          {/* Latest Post */}
          <div>
            <h4 className="text-xl font-semibold text-yellow-300 mb-4">Latest Posts</h4>
            {[{ img: image1, text: "Haridwar University's Journey of Empowering Future Leaders", date: "15 March, 2025" },
              { img: image2, text: "Why Haridwar University Is the Top Choice for Future Leaders", date: "20 March, 2025" }
            ].map((post, index) => (
              <Link to={`/blogpost${index + 1}`} key={index} onClick={handleClick} className="flex items-center gap-3 mb-3 group">
                <img src={post.img} alt="Blog" className="w-14 h-14 rounded-md" />
                <div>
                  <p className="text-sm text-gray-300 group-hover:text-yellow-300 transition">{post.text}</p>
                  <span className="text-xs text-gray-400">{post.date}</span>
                </div>
              </Link>
            ))}
          </div>

          {/* Quick Links */}
          <div>
            <h4 className="text-xl font-semibold text-yellow-300 mb-4">Quick Links</h4>
            <ul className="space-y-2 text-gray-300">
              {[
                "Haridwar University Udaipur",
                "Haridwar University Rajasthan",
                "Haridwar University India",
                "Haridwar University Courses",
                "Haridwar University Placement",
                "Haridwar University Admission Form"
              ].map((link, index) => (
                <li key={index} className="hover:text-yellow-300 transition cursor-pointer">{link}</li>
              ))}
            </ul>
          </div>

          {/* Contact Info */}
          <div className="p-4 bg-gray-800 rounded-lg">
      <h4 className="text-xl font-semibold text-yellow-300 mb-4">Contact Us</h4>
      <p className="text-gray-300">Need assistance? Reach out to us:</p>
      <p className="text-lg font-bold text-yellow-300 mt-2">{uni?.phone}</p>
      <p className="text-gray-300">{uni?.address}</p>
      {/* Link to the Privacy Policy page */}
      <Link to="/privacyPolicy" className="mt-4 inline-block text-blue-500 underline">
        Privacy Policy
      </Link>
    </div>
        </div>

        {/* Disclaimer Section */}
        <div className="mt-10 text-gray-400 text-sm border-t border-white/20 pt-6">
          <h5 className="text-lg font-semibold text-yellow-300 mb-2">Disclaimer</h5>
          <p className="leading-relaxed">
          Disclaimer: The purpose of aaopadhe is to provide accurate and unbiased information about universities and their programs of study to admission aspirants. The content on the aaopadhe website, including texts, graphics, images, blogs, videos, university logos, and other materials (collectively referred to as "Content"), is provided for informational purposes only. The content is not intended to replace or substitute for any offerings or services provided by our academia partners. We do not endorse or deliberately infringe upon any intellectual property or associated rights of . The information provided by aaopadhe on www.aaopadhe.in, as well as any mobile or other applications, is offered in good faith and to the best of our knowledge and abilities. However, we make no representation or warranty, whether express or implied, regarding the accuracy, adequacy, validity, reliability, or completeness of the information provided on the website or in our applications. While we strive to maintain accurate and up-to-date information, errors or omissions may occur. Users can also visit the official website of for the most up-to-date and accurate information regarding admissions, programs, courses, fees, deadlines, and any other relevant details. By using the aaopadhe website or any of our applications, you acknowledge that you have read, understood, and agreed to the terms and conditions of this disclaimer. If you do not agree with any part of this disclaimer, we kindly request that you refrain from using our platform. Please be aware that this disclaimer may be subject to change without prior notice. We recommend reviewing the disclaimer periodically for any updates or modifications. If you have any questions or concerns regarding this disclaimer, please contact us.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
