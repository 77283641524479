import React from "react";

const LoanFec = ({ uni }) => {
  return (
    <section className="w-full md:py-12 py-1 bg-gradient-to-b from-blue-50 to-indigo-50 flex justify-center text-gray-800">
      <div className="w-[90%] max-w-3xl bg-white backdrop-blur-lg shadow-lg border border-blue-100 rounded-2xl p-6">
        <h2 className="text-xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-indigo-600 mb-4">
          Education Loan Facility
        </h2>
        <p className="text-gray-700 text-sm md:text-md">{uni?.loanBNK || "Loan facility available at partner banks."}</p>

        <div className="mt-6">
          <h3 className="font-semibold text-sm md:text-md text-blue-800">Contact Details</h3>
          <ul className="mt-2 text-gray-700 text-sm space-y-1">
            <li>
              <span className="font-medium text-sm">Education Loan Coordinator</span>
            </li>
            <li>
              📞 <span className="font-medium">{uni?.phone || "N/A"}</span>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default LoanFec;