import React from "react";
import { motion } from "framer-motion";

const OurConnection = ({ uni }) => {
  return (
    <section className="md:py-12 py-2 bg-gray-100 text-gray-900 text-center">
      <motion.h1
        initial={{ opacity: 0, y: -15 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-md md:text-3xl font-bold text-gray-800"
      >
        Our Students' Success Ratio
      </motion.h1>

      <div className="flex flex-wrap justify-center gap-8 md:mt-10 mt-2">
        {[
          { label: "Students Enrolled", value: `${uni?.studentEnroll}+` },
          { label: "Registered Instructors", value: `${uni?.registeredInstructor}+` },
          { label: "Success Rate", value: `${uni?.successRate}%` },
        ].map((item, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            className="bg-white shadow-md border border-gray-200 rounded-lg p-6 w-56 text-center hover:shadow-lg transition-all duration-300"
          >
            <p className="md:text-3xl text-sm font-bold text-blue-600">{item.value}</p>
            <p className="text-sm md:text-3xl font-medium text-gray-600 mt-2">{item.label}</p>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default OurConnection;
