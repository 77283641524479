import React from "react";
import { motion } from "framer-motion";
import "./index.css";
import hostel from "./image/hostel.png";
import cafe from "./image/cafe.png";
import lab from "./image/lab.png";
import computerLab from "./image/lab-technician.png";
import medical from "./image/medical-team.png";
import transport from "./image/shipment.png";
import gym from "./image/weightlifter.png";
import wifi from "./image/wifi.png";
import ci from "./image/cinema.png";
import library from "./image/library (1).png";

const UniversityFacilities = ({ uni }) => {
  const facilities = [
    { name: "Gym", image: gym },
    { name: "Hostel", image: hostel },
    { name: "Cafeteria", image: cafe },
    { name: "Laboratory", image: lab },
    { name: "Computer Labs", image: computerLab },
    { name: "Auditorium", image: ci },
    { name: "Wi-Fi Campus", image: wifi },
    { name: "Transport", image: transport },
    { name: "Medical", image: medical },
    { name: "Library", image: library },
  ];

  return (
    <div className="relative w-full py-4 flex flex-col items-center justify-center text-white px-4 md:px-8 py-12 bg-gradient-to-b from-blue-50 to-indigo-50">
      {/* Background Gradient */}
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-blue-50 to-indigo-50"></div>

      {/* Overlay */}
      <div className="absolute inset-0 bg-white/30 backdrop-blur-md"></div>

      {/* Title */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="relative text-center px-4 md:px-12 z-10"
      >
        <h1 className="text-lg md:text-4xl font-extrabold tracking-wide bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-indigo-600">
          {uni && uni.university} Facilities
        </h1>
      </motion.div>

      {/* Facilities Grid */}
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="relative w-full max-w-5xl mt-8 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-6 z-10"
      >
        {facilities.map((facility, index) => (
          <motion.div
            key={index}
            whileHover={{ scale: 1.05 }}
            className="bg-white backdrop-blur-lg border border-blue-100 p-4 md:p-5 rounded-xl shadow-md flex flex-col items-center justify-center text-center transition-all duration-300 hover:bg-blue-50"
          >
            <img
              src={facility.image}
              alt={facility.name}
              className="w-14 h-14 md:w-16 md:h-16 object-contain mb-2"
            />
            <h2 className="text-[0.8rem] md:text-lg font-medium text-blue-900">{facility.name}</h2>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default UniversityFacilities;